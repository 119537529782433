<template>
  <loading v-if="processing" />
  <div
    :class="{ editStreamRoomMargins: menuModal == 'editStreamRoomModal' }"
    id="editStreamRoom"
    class="modal"
  >
    <div class="header"><h2>Edit Stream Room</h2></div>
    <div class="modal-content">
      <div class="form-wrap background">
        <form class="createUser">
          <div class="form-header">
            <h5>STREAM INFORMATION</h5>
          </div>
          <div class="inputs">
            <div class="input">
              <label
                >Room Name<span
                  class="error"
                  v-for="error of v$.roomName.$errors"
                  :key="error.$uid"
                  >*</span
                ></label
              >
              <input
                type="text"
                placeholder="Room Name"
                v-model="v$.roomName.$model"
              />
            </div>
            <!-- <div class="input">
              <label>Password</label>
              <input type="text" placeholder="Password" v-model="password" />
            </div> -->

            <div class="form-header">
              <h5>SYSTEM</h5>
            </div>

            <div class="input">
              <label>Publish Token</label>
              <input
                id="publishToken"
                type="text"
                readonly="readonly"
                v-model="publishToken"
                @click.prevent="copyPublishToken"
              />
            </div>

            <div class="input">
              <label>Project</label>
              <!-- <input
                type="text"
                placeholder="Choose a Project..."
                v-model="projectName"
              /> -->
              <VueMultiselect
                v-model="projectName"
                :options="projectList"
                :close-on-select="true"
                :clear-on-select="false"
                :preselectFirst="true"
                placeholder="Type to search"
                label="projectName"
                track-by="name"
                @search-change="asyncFindProject"
              />
            </div>

            <div class="input">
            <label>Streaming Server Location</label>
              <VueMultiselect
                v-model="streamServer"
                :options="streamServerList"
                :close-on-select="true"
                :clear-on-select="false"
                :searchable="false"
                :show-labels="false"
                :preselectFirst="true"
                placeholder="Type to search"
                label="name"
                track-by="id"
                class="noShrink"
              />
            </div>

            <div class="input">
            <label>Chat Server Location</label>
              <VueMultiselect
                v-model="chatServer"
                :options="chatServerList"
                :close-on-select="true"
                :clear-on-select="false"
                 :searchable="false"
                :show-labels="false"
                :preselectFirst="true"
                placeholder="Type to search"
                label="name"
                track-by="id"
                class="noShrink"
                @search-change="asyncFindProject"
              />
            </div>

            <div class="input">
              <label>Watermark</label>
              <input
                class="checkbox"
                type="checkbox"
                id="enableWatermark"
                v-model="enableWatermark"
              />
            </div>
            <!-- <div class="form-header">
              <h5>SESSION</h5>
            </div> -->

            <div class="form-header">
              <h5>ASSIGN USERS</h5>
            </div>
            <div class="addUsers input">
              <!-- <input
                type="text"
                placeholder="Choose a Project..."
                v-model="projectName"
              /> -->
              <VueMultiselect
                id="permitUsers"
                class="addUsers"
                v-model="newPermittedUsers"
                :options="userList"
                open-direction="bottom"
                :close-on-select="false"
                :clear-on-select="true"
                :hideSelected="true"
                :showLabels="false"
                :preselectFirst="true"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                tag-placeholder="Add this as new tag"
                placeholder="Type to search or add tag"
                label="email"
                track-by="uid"
                @search-change="asyncFindUser"
              />
            </div>
            <div v-show="error" class="error">{{ this.errorMsg }}</div>
          </div>
          <button @click.prevent="createStreamRoom">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import { timestamp, auth, rtdb, db } from "../firebase/firebaseInit.js";
import loading from "../Loading.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
// import { v4 as uuidv4 } from "uuid";
import VueMultiselect from "vue-multiselect";
import "../vue-multiselect/vue-multiselect.css";
import _, { map } from "underscore";

export default {
  name: "Create User Modal",
  props: ["streamRoomId", "menuModal"],
  emits: ["close-modal"],
  data() {
    return {
      roomName: "",
      password: "",
      publishToken: "",
      projectName: [],
      uid: "",
      projectList: [],
      chatServer: [],
      chatServerList: [],
      streamServer: [],
      streamServerList: [],
      error: "",
      errorMsg: "",
      userList: [],
      permittedUsers: [],
      newPermittedUsers: [],
      removedUsers: [],
      processing: false,
      enableWatermark: null,
    };
  },
  components: {
    loading,
    VueMultiselect,
  },
  watch: {
    // whenever newPermittedUsers changes, this function will run
    newPermittedUsers: function () {
      let a = this.newPermittedUsers;
      let b = this.permittedUsers;

      var difference = function (array) {
        var rest = Array.prototype.concat.apply(
          Array.prototype,
          Array.prototype.slice.call(arguments, 1)
        );

        var containsEquals = function (obj, target) {
          if (obj == null) return false;
          return _.any(obj, function (value) {
            return _.isEqual(value, target);
          });
        };

        return _.filter(array, function (value) {
          return !containsEquals(rest, value);
        });
      };

      this.removedUsers = difference(b, a);
      // console.log(this.removedUsers);
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      roomName: { required },
    };
  },
  created() {
    console.log(this.streamRoomId);
    console.log(this.publishToken);


    db.collection("serviceServers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          if (doc.data().serverType == "omewebrtc") {
          this.streamServerList.push({
            name: doc.data().name,
            id: doc.id,
          });
          } else if (doc.data().serverType == "jitsiChat") {
          this.chatServerList.push({
            name: doc.data().name,
            id: doc.id,
          });
          }
        });
      });
    db.collection("streamRooms")
      .doc(this.streamRoomId)
      .collection("permittedUsers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log({ email: doc.data().userEmail, uid: doc.id });
          this.permittedUsers.push({
            email: doc.data().userEmail,
            uid: doc.id,
          });
          this.newPermittedUsers.push({
            email: doc.data().userEmail,
            uid: doc.id,
          });
        });
      });
    db.collection("streamRooms")
      .doc(this.streamRoomId)
      .get()
      .then((doc) => {
        let projectRef = doc.data().projectNameInsensitive;
        console.log(doc.data());
        this.roomName = doc.data().roomName;
        this.publishToken = doc.data().publishToken;
        this.enableWatermark = doc.data().enableWatermark;
        this.streamServer = doc.data().streamServer;
        this.chatServer = doc.data().chatServer;
        db.collection("projects")
          .where("projectNameInsensitive", "==", projectRef)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.projectName = doc.data();
              console.log(doc.id, " => ", doc.data());
            });
          });
      });
  },
  mounted() {},
  methods: {
    asyncFindProject(query) {
      if (query == "") {
        this.projectList = [];
      } else {
        let collectionRef = db
          .collection("projects")
          .where(
            "projectNameInsensitive",
            ">=",
            query.toLowerCase().replace(/\s/g, "")
          )
          .where(
            "projectNameInsensitive",
            "<=",
            query.toLowerCase().replace(/\s/g, "") + "\uf8ff"
          );
        collectionRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (
              this.projectList.some(
                (project) =>
                  project.projectNameInsensitive ===
                  doc.data().projectNameInsensitive
              )
            ) {
            } else {
              this.projectList.push(doc.data());
            }
          });
        });
      }
    },
    asyncFindUser(query) {
      if (query == "") {
        this.userList = [];
      } else {
        let emailSearch = db.collection("users").where("email", ">=", query);
        emailSearch.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (this.userList.some((user) => user.email === doc.data().email)) {
            } else {
              this.userList.push({ email: doc.data().email, uid: doc.id });
            }
          });
        });
      }
    },
    addTag(newTag) {
      const tag = {
        email: newTag,
        uid: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.permittedUsers.push(tag);
      this.newPermittedUsers.push(tag);
    },
    copyPublishToken() {
      navigator.clipboard.writeText(this.publishToken);
    },
    closeModal() {
      this.processing = false;
      this.$emit("close-modal");
    },
    async createStreamRoom() {
      if (this.roomName !== "" && this.projectName !== "") {
        this.error = false;
        this.errorMsg = "";
        const streamRoomDatabase = db.collection("streamRooms");
        console.log(this.projectName);
        await streamRoomDatabase
          .doc(this.streamRoomId)
          .update({
            // creatorId: this.$store.state.profileId,
            // creatorEmail: this.$store.state.profileEmail,
            roomName: this.roomName,
            roomNameInsensitive: this.roomName.toLowerCase().replace(/\s/g, ""),
            projectName: this.projectName.projectName,
            projectNameInsensitive: this.projectName.projectNameInsensitive,
            enableWatermark: this.enableWatermark,
            streamServer: this.streamServer,
            chatServer: this.chatServer,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(async () => {
            // REMOVE USERS FROM PERMITTED USER COLLECTION
            await this.removedUsers.forEach(async (user) => {
              await streamRoomDatabase
                .doc(this.streamRoomId)
                .collection("permittedUsers")
                .doc(user.uid)
                .delete()
                .then(() => {
                  console.log("Document successfully deleted!");
                  db.collection("users")
                    .doc(user.uid)
                    .collection("roomAccess")
                    .doc(this.streamRoomId)
                    .delete();
                })
                .catch((error) => {
                  console.error("Error removing document: ", error);
                });
            });

            await this.newPermittedUsers.forEach(async (user) => {
              // ADD ROOM ID TO PERMITTED USERS DOC
              await db
                .collection("users")
                .doc(user.uid)
                .collection("roomAccess")
                .doc(this.streamRoomId)
                .set({
                  streamRoomId: this.streamRoomId,
                })
                .then(() => {
                  console.log("Document successfully Created!");
                })
                .catch((error) => {
                  console.error("Error setting document: ", error);
                });

              // ADD NEW USERS TO PERMITTED USER COLLECTION

              await streamRoomDatabase
                .doc(this.streamRoomId)
                .collection("permittedUsers")
                .doc(user.uid)
                .set(
                  {
                    userEmail: user.email,
                    uid: user.uid,
                    invitedBy: "Current User Name",
                    dateAdded: firebase.firestore.FieldValue.serverTimestamp(),
                  },
                  { merge: true }
                );
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          }),
          this.closeModal();
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return;
    },
  },
};
</script>



<style lang="scss">
.vti__input {
  height: 28px !important;
  margin: 0px !important;
}

.vti__dropdown {
  margin: 0px !important;
  height: 26px !important;
  width: 50px !important;
  padding: 0px !important;
  margin-right: 5px !important;
}

.vue-tel-input {
  display: flex !important;
  text-align: left !important;
  width: 207px !important;
  margin-bottom: 5px !important;
}

.vti__dropdown-list {
  z-index: 1002 !important;
}
</style>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  flex-grow: 3;
  max-width: 360px;
  background-color: #2f323c;
  height: 600px;
  margin-left: 10px;

  .header {
    background-color: #383c49;
    height: 70px;
    display: flex;
    align-items: center;
    color: #e5ecff;
    font-size: 1.5em;
    font-weight: 700;
    padding-left: 25px;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  padding: 15px 15px;
  height: 100%;
  background-color: #2f323c;

  .createUser {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: RGB(239, 243, 255);
    flex-shrink: 2;
  }

  input {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  #publishToken {
    color: #eff3ff73;
  }

  select {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 0px 0px 5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5px 0px 2.5px 0px;
  }

  button {
    background-color: RGB(0, 132, 255);
    border: none;
    height: 37px;
    width: 100%;
    color: #eff3ff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }

  button:hover {
    background-color: #0084ff;
  }
}

.form-header {
  border-bottom: 1px solid RGB(123, 138, 173);
  margin-bottom: 10px;
  display: flex !important;
}

.form-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.addUsers {
  height: 160px;
}

.error {
  color: #ad0000 !important;
  font-size: 14px;
}

$checkbox-size: 20px;

input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  border-radius: 5px;
  border: #eff3ff 2px solid;
  margin-right: 186px;
  margin-top: 5px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: rgb(178, 199, 255, 0);
    background-color: #383c49;
    border-radius: 5px;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
    transition: background-color 350ms 0ms ease-out, color 50ms 50ms ease-in,
      font-size 75ms 150ms ease-out;
  }

  &:checked::after {
    content: "\02714";
    color: #b2c7ff;
    font-size: 15px;
  }
}
.noShrink {
    flex-shrink: 0;
  }
</style>